import React from 'react'
import styled from '@emotion/styled'
import babyImage from '../static/fox-baby.png'
import numberToText from 'number-to-text/converters/en-us'

const colors = {
  blue1: "#B3C8CF",
  blue2: "#BED7DC",
  yellow1: "#F1EEDC",
  yellow2: "#E5DDC5",
}

const Center = styled.div({
  placeItems: 'center',
  display: 'grid',
  height: '100vh',
  color: colors.blue1,
  backgroundColor: colors.yellow1
})

const Wrapper = styled.div({
  placeItems: 'center',
  display: 'grid',
})

const Header = styled.h2({
  fontSize: '8rem',
  width: '100%',
  textAlign: 'center',
  margin: '1rem',
})

const Links = styled.h2({
  fontSize: '4rem',
  width: '100%',
  textAlign: 'center',
  margin: '0.5rem',
  '& a': {
    color: colors.blue1,
    textDecoration: 'none',
    '&:hover': {
      color: colors.yellow2
    }
  }
})

const countDownDate = new Date("Nov 26, 2024 01:00:00").getTime();

const App = () => {
  const now = new Date().getTime();
  const distance = countDownDate - now;

  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const weeks = Math.floor(days / 7);

  return (
    <Center>
      <Wrapper>
        { days <= 0 ? <Header>Welcome 'baby name goes here'</Header> :
          <>
            {weeks > 1 ? <Header>{`${numberToText.convertToText(weeks, {case:"lowerCase"})} week${s(weeks)}`}</Header> : <Header>{`${numberToText.convertToText(days, {case:"lowerCase"})} day${s(days)}`}</Header>}
            <Header>to go</Header>
          </>
        }
        <img style={{ width: "50rem" }} src={babyImage} />
        { weeks > 1 ? <Links>
          <a href="https://www.babybunting.com.au/gift-registry/view/03e7dffa-dacc-47d0-a773-ecda2b939a0b">Gift Registry</a>
        </Links> : <>?</>}
      </Wrapper>
    </Center>
  )
}

const s = (total: number) => total === 1 ? "" : 's'

export default App
